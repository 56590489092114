import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import removeMd from 'remove-markdown';
import { News } from '../components';
import { getMetadata } from '../helpers';

const ContentNews = (props = {}) => {
  const {
    pageContext = {},
  } = props;
  return (
    <div className="content content--news">
      <Helmet
        title={get(pageContext, 'title', '')}
        meta={getMetadata({
          metaDescription: {
            metaDescription: removeMd(get(pageContext, 'copy.copy', '').split(' ').slice(0, 25).join(' ')),
          },
          metaTitle: get(pageContext, 'title', ''),
          metaType: 'Article',
          ogImage: get(pageContext, 'image', ''),
        })}
      />
      <News {...pageContext} />
    </div>
  );
};

ContentNews.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
};

export default ContentNews;
